import { CookieConsentConfig } from '@cleverconnect/ngx-cookie-consent';

export class Config {
  public redirectOnError: boolean = true;
  public apiUrl: string;
  public authUrl: string;
  public v1Url: string;
  public liveUrl: string;
  public tableauUrl: string;
  public tokenStorageKey: string;
  public isLocal: boolean = false;
  public cookieConsent: CookieConsentConfig;
  public sentryUrl: string | undefined;
  public context: string;
  public environment: string;
  public posthogKey?: string;
  public urlPosthog?: string;
  public helpDeskUrl?: string;
}

export enum EnvironmentEnum {
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production',
}
