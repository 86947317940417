import { NgZone, ElementRef, OnDestroy, AfterViewInit, Input, Directive, Output, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import am4lang_fr from '@amcharts/amcharts4/lang/fr_FR';
import am4lang_en from '@amcharts/amcharts4/lang/en';
import am4lang_es from '@amcharts/amcharts4/lang/es_ES';
import am4lang_it from '@amcharts/amcharts4/lang/it_IT';
import am4lang_de from '@amcharts/amcharts4/lang/de_DE';
import am4lang_nl from '@amcharts/amcharts4/lang/nl_NL';
import am4lang_da from '@amcharts/amcharts4/lang/da_DK';

const am4locales: { [key: string]: any } = {
  fr: am4lang_fr,
  en: am4lang_en,
  es: am4lang_es,
  it: am4lang_it,
  de: am4lang_de,
  nl: am4lang_nl,
  da: am4lang_da,
};

@Directive({
  selector: '[appChart]',
  exportAs: 'chart',
})
export class ChartDirective<T extends am4charts.Chart> implements OnDestroy, AfterViewInit {
  public chart: T;
  @Input('appChart') private type: string;
  @Output() public load = new EventEmitter<void>();
  @Output() public dispose = new EventEmitter<void>();

  private _minHeight: number = 300;
  public get minHeight(): number {
    return this._minHeight;
  }
  @Input()
  public set minHeight(value: number) {
    this._minHeight = value;
    if (this.chart) {
      this.chart.svgContainer.htmlElement.style.height = `${value}px`;
    }
  }

  constructor(private zone: NgZone, private elementRef: ElementRef, @Inject(LOCALE_ID) private locale: string) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      am4core.useTheme(am4themes_animated);
      const chart = (this.chart = am4core.create(this.elementRef.nativeElement, this.type as any));
      chart.language.locale = am4locales[this.locale];
      if (chart instanceof am4charts.XYChart) {
        chart.zoomOutButton.marginRight = -10;
        chart.zoomOutButton.marginTop = 25;
        chart.mouseWheelBehavior = 'zoomX';
        chart.horizontalMouseWheelBehavior = 'panX';
        chart.mouseOptions.sensitivity = 10000000;
      }
      chart.exporting.menu = new am4core.ExportMenu();
      this.minHeight = this._minHeight;

      this.load.emit();
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
      this.dispose.emit();
    });
  }
}
