import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { Company } from '../company';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;

  constructor(
    private http: HttpClient,
    configService: DynamicConfigService,
  ) {
    this.config = configService.get<Config>();
  }

  private datas$: Observable<Company>[] = [];

  public get(companyId: number | 'first'): Observable<Company> {
    if (!this.datas$[companyId]) {
      this.datas$[companyId] = this.http.get<Company>(`${this.config.apiUrl}/panel/companies/${companyId}`).pipe(shareReplay(1));
    }

    return this.datas$[companyId];
  }

  public save(company: Company) {
    const url = `${this.config.apiUrl}/panel/companies`;
    if (company.id) {
      delete this.datas$[company.id];
      return this.http.put<Company>(`${url}/${company.id}`, company, { headers: this.headers });
    } else {
      return this.http.post<Company>(url, company, { headers: this.headers });
    }
  }

  public patch(company: Partial<Company>, id: number = company.id) {
    const url = `${this.config.apiUrl}/panel/companies/${id}`;
    delete this.datas$[id];
    return this.http.patch<Company>(url, company, { headers: this.headers });
  }

  public getResourceTableau(): Observable<{ dashboard: string; trustedTicketTableau: string }> {
    const resourceTableauUrl = `${this.config.apiUrl}/tableau`;
    return this.http.get<{ dashboard: string; trustedTicketTableau: string }>(resourceTableauUrl);
  }

  getIndustries(): Observable<string[]> {
    const url = `${this.config.apiUrl}/panel/companies/industries`;

    return this.http.get<string[]>(url);
  }
}
