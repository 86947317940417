import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GoogleAnalyticsProvider } from './providers/google-analytics.provider';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  private scriptTag: string = '';

  public ngOnInit() {
    const googleAnalyticsProvider = new GoogleAnalyticsProvider();

    this.scriptTag += googleAnalyticsProvider.script('UA-47466859-9');
  }

  public ngAfterViewInit(): void {
    if (!this.scriptTag) {
      return;
    }
    const s: HTMLScriptElement = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = this.scriptTag;
    document.body.appendChild(s);
  }
}
